// analytics.js
export const GA_TRACKING_ID = 'G-2NN9K76847';

// Function to initialize Google Analytics (optional if using gtag script in index.html)
export const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);
};

// Function to track page views
export const trackPageView = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// Function to track specific events (optional)
export const trackEvent = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
