   // frontend/src/components/CookieConsent.js
   import React, { useState, useEffect } from 'react';

   function CookieConsent() {
     const [isVisible, setIsVisible] = useState(false);

     useEffect(() => {
       const consent = localStorage.getItem('cookieConsent');
       if (!consent) {
         setIsVisible(true);
       }
     }, []);

     const handleAccept = () => {
       localStorage.setItem('cookieConsent', 'true');
       setIsVisible(false);
     };

     const handleDecline = () => {
       localStorage.setItem('cookieConsent', 'false');
       setIsVisible(false);
     };

     if (!isVisible) return null;

     return (
       <div className="cookie-consent-banner">
         <p>
           We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{" "}
           <a href="/cookies-policy">Learn More</a>
         </p>
         <div className="cookie-buttons">
           <button onClick={handleAccept}>Accept</button>
           <button onClick={handleDecline}>Decline</button>
         </div>
       </div>
     );
   }

   export default CookieConsent;