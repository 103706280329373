import React, { useState } from 'react';
import { hatch } from 'ldrs';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { subscribeCustomer, generateChallengeImage } from '../utils/serverComm';
import '../styles/ChallengePage.css';

// Initialize the loader
hatch.register();

function ChallengePage() {
  const [currentStep, setCurrentStep] = useState('welcome');
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    firstName: '',
    surname: '',
    company: '',
    role: '',
    consent: false
  });
  const [prompt, setPrompt] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [attemptsLeft, setAttemptsLeft] = useState(3);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validatePhone = (phone) => {
    // Remove all non-digit characters except + and ()
    const cleanPhone = phone.replace(/[^\d+()]/g, '');
    // Remove everything except digits to count length
    const digitsOnly = cleanPhone.replace(/\D/g, '');
    return digitsOnly.length <= 15 && /^[+()?\d]+$/.test(cleanPhone);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    if (!formData.consent) {
      setError('Please accept the terms to continue');
      return;
    }

    if (!validatePhone(formData.phone)) {
      setError('Please enter a valid phone number (max 15 digits)');
      const phoneInput = document.querySelector('input[type="tel"]');
      phoneInput.classList.add('error-shake');
      setTimeout(() => phoneInput.classList.remove('error-shake'), 500);
      return;
    }

    try {
      const companyInfo = formData.company && formData.role 
        ? `${formData.company}:${formData.role}`
        : formData.company || formData.role || '';

      const response = await subscribeCustomer({
        email: formData.email,
        firstName: formData.firstName,
        surname: formData.surname || '',
        phone: formData.phone,
        companyInfo,
        tag: 'challenge'
      });
      
      if (response.success) {
        setCurrentStep('game');
      } else {
        setError(response.message);
        const emailInput = document.getElementById('email');
        emailInput.classList.add('error-shake');
        setTimeout(() => emailInput.classList.remove('error-shake'), 500);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Only update if the new value is valid or empty
    if (value === '' || validatePhone(value)) {
      setFormData({ ...formData, phone: value });
    }
  };

  const handleGenerateImage = async () => {
    if (!prompt) {
      setError('Please enter a prompt');
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const response = await generateChallengeImage(prompt, formData.email);
      if (response.success) {
        setGeneratedImage(response.imagePath);
        setAttemptsLeft(prev => prev - 1);
      } else {
        setError(response.message);
        if (response.shouldCountAttempt) {
          setAttemptsLeft(prev => prev - 1);
        }
      }
    } catch (error) {
      setError('Failed to generate image. Please try again.');
      setAttemptsLeft(prev => prev - 1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitChallenge = () => {
    setCurrentStep('complete');
  };

  const handleClearPrompt = () => {
    setPrompt('');
  };

  return (
    <div className="challenge-page">
      <Navbar />
      
      <main className="challenge-content">
        {currentStep === 'welcome' && (
          <div className="welcome-section">
            <h1>Welcome to AI Image Challenge by Tales.Travel</h1>
            <p>The rules are simple: we'll show you an image, and your goal is to create a prompt for AI model to generate an image as close as possible to the original.</p>
            <div className="prize-info">
              <p>🎉 Get ready for some creative fun at Web Summit 2024 in Lisbon! Three lucky winners will receive a $50 Hotels.com gift card each!</p>
              <p>We'll announce the winners one week after Web Summit across our social media channels - follow us on LinkedIn and other platforms to see if you're one of our creative champions! 🏆</p>
            </div>
            <button 
              className="cta-button"
              onClick={() => setCurrentStep('register')}
            >
              Start Challenge
            </button>
          </div>
        )}

        {currentStep === 'register' && (
          <div className="register-section">
            <h2>Let's authenticate</h2>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  placeholder="Email*"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone* (max 15 symbols, only numbers, +, and () allowed)"
                  required
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  onKeyPress={(e) => {
                    // Allow only digits, +, (, and )
                    if (!/[\d+()]/.test(e.key)) {
                      e.preventDefault();
                    }
                    // Prevent input if it would exceed 15 digits
                    const digitsOnly = (formData.phone + e.key).replace(/\D/g, '');
                    if (digitsOnly.length > 15 && /\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="First Name*"
                  required
                  value={formData.firstName}
                  onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Surname"
                  value={formData.surname}
                  onChange={(e) => setFormData({...formData, surname: e.target.value})}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Company"
                  value={formData.company}
                  onChange={(e) => setFormData({...formData, company: e.target.value})}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Role"
                  value={formData.role}
                  onChange={(e) => setFormData({...formData, role: e.target.value})}
                />
              </div>
              <div className="consent-wrapper">
                <label className="consent-label">
                  <input
                    type="checkbox"
                    checked={formData.consent}
                    onChange={(e) => setFormData({...formData, consent: e.target.checked})}
                  />
                  <span className="consent-text">
                    Yes, I agree to receive result of competition, newsletters, updates, and offers from Tales.Travel and accept the{' '}
                    <a 
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </label>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit" className="cta-button">Begin Your Creative Journey</button>
            </form>
          </div>
        )}

        {currentStep === 'game' && (
          <div className="game-section">
            <h2>Guess the Prompt</h2>
            <p>Try to create an image that matches our hidden prompt. You have {attemptsLeft} attempts left.</p>
            
            <div className="challenge-image">
              <img src="/images/challenge.jpg" alt="Challenge" />
              <p>Original picture</p>
              {isLoading && (
                <div className="loading-overlay">
                  <l-hatch
                    size="40"
                    stroke="4"
                    speed="4.3" 
                    color="black"
                  ></l-hatch>
                  <p>Generating your image...</p>
                </div>
              )}
            </div>
            
            <div className="textarea-wrapper">
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                maxLength={1000}
                placeholder="Enter your prompt here..."
              />
              {prompt && (
                <i 
                  className="fas fa-times clear-icon" 
                  onClick={handleClearPrompt}
                  title="Clear prompt"
                ></i>
              )}
            </div>
            
            {generatedImage && (
              <div className="generated-image">
                <img 
                  src={generatedImage} 
                  alt="Generated" 
                  onError={(e) => {
                    console.error('Error loading image');
                    e.target.src = '/images/error-placeholder.png';
                  }}
                />
                <div className="action-buttons">
                  <button 
                    className="try-again-button"
                    onClick={handleGenerateImage}
                    disabled={attemptsLeft === 0 || isLoading}
                  >
                    Try Again ({attemptsLeft} left)
                  </button>
                  <button 
                    className="submit-button"
                    onClick={handleSubmitChallenge}
                    disabled={isLoading}
                  >
                    Submit for Competition
                  </button>
                </div>
              </div>
            )}
            
            {!generatedImage && (
              <button 
                className="generate-button"
                onClick={handleGenerateImage}
                disabled={isLoading}
              >
                Generate Image
              </button>
            )}
          </div>
        )}

        {currentStep === 'complete' && (
          <div className="complete-section">
            <h2>Congratulations!</h2>
            <p>You have successfully completed the Web Summit 2024 Lisbon challenge. We will send updates to the email you provided.</p>
            <p>If you liked the challenge please follow us on{' '}
              <a href="https://www.linkedin.com/company/talestravel/" 
                 target="_blank" 
                 rel="noopener noreferrer">LinkedIn</a>
              {' '}or visit our{' '}
              <a href="https://tales.travel" 
                 target="_blank" 
                 rel="noopener noreferrer">website</a>
            </p>
            <p>Have a great day!</p>
          </div>
        )}
      </main>

      <Footer />
    </div>
  );
}

export default ChallengePage;
