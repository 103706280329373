import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import MapComponent from '../components/MapComponent'; // Import MapComponent
import '../styles/TravelPlanPage.css';

function TravelPlanPageMockup() {
  const [destination] = useState('Barcelona');
  const [persons, setPersons] = useState(1);
  const [style, setStyle] = useState('');
  const [budget, setBudget] = useState(139);
  const [days, setDays] = useState(3);
  const [expandedDays, setExpandedDays] = useState({});
  const [truncatedDescriptions, setTruncatedDescriptions] = useState({});
  const descriptionRefs = useRef({});

  const mockItinerary = [
    {
      day: 1,
      title: "Arrival in Barcelona",
      description: "Arrive at Barcelona–El Prat Airport and transfer to the hotel. Explore the nearby attractions such as the Gothic Quarter, Sagrada Familia, and Park Güell. Walk around the city and enjoy the local cuisine.",
      weather: { temp: 14, condition: "Partly Cloudy" },
      activities: []
    },
    {
      day: 2,
      title: "Sagrada Familia and Park Güell",
      description: "Visit the iconic Sagrada Familia in the morning and enjoy the artistic atmosphere of Park Güell in the afternoon.",
      weather: { temp: 14, condition: "Partly Cloudy" },
      activities: []
    },
    {
      day: 3,
      title: "Gothic Quarter and Beach",
      description: "Explore the historic Gothic Quarter in the morning and relax at Barceloneta Beach in the afternoon.",
      weather: { temp: 14, condition: "Partly Cloudy" },
      budget: { food: 20, activities: 15, transport: 10 },
      activities: [
        { time: "09:00 AM", description: "Breakfast at La Boqueria Market", icon: "utensils" },
        { time: "10:30 AM", description: "Walking tour of Gothic Quarter", icon: "walking" },
        { time: "12:00 PM", description: "Lunch at El Nacional", icon: "utensils" },
        { time: "02:00 PM", description: "Visit Barcelona Cathedral", icon: "church" },
        { time: "04:00 PM", description: "Relax at Barceloneta Beach", icon: "umbrella-beach" },
        { time: "07:00 PM", description: "Dinner at La Barceloneta", icon: "utensils" }
      ]
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      const newTruncatedDescriptions = {};
      mockItinerary.forEach((day) => {
        const descriptionElement = descriptionRefs.current[day.day];
        if (descriptionElement) {
          newTruncatedDescriptions[day.day] = descriptionElement.scrollWidth > descriptionElement.clientWidth;
        }
      });
      setTruncatedDescriptions(newTruncatedDescriptions);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, [mockItinerary]);

  const toggleDayExpansion = (day) => {
    setExpandedDays(prev => ({ ...prev, [day]: !prev[day] }));
  };

  return (
    <div className="travel-plan-page">
      <Navbar />
      <main className="travel-plan-content">
        {/* Top Section */}
        <div className="top-section">
          <div className="left-column">
            <div className="destination-image">
              <h1>Your trip to {destination}</h1>
            </div>
          </div>
          <div className="right-column">
            <div className="right-column-content">
              <div className="plan-options">
                <h3>Customize Your Plan</h3>
                <div className="option-group">
                  <label htmlFor="persons">Persons</label>
                  <input
                    type="number"
                    id="persons"
                    value={persons}
                    onChange={(e) => setPersons(e.target.value)}
                    min="1"
                  />
                </div>
                <div className="option-group">
                  <label htmlFor="style">Style</label>
                  <select
                    id="style"
                    value={style}
                    onChange={(e) => setStyle(e.target.value)}
                  >
                    <option value="">Select style</option>
                    <option value="budget">Budget</option>
                    <option value="comfort">Comfort</option>
                    <option value="luxury">Luxury</option>
                  </select>
                </div>
                <div className="option-group">
                  <label htmlFor="budget">Budget</label>
                  <input
                    type="number"
                    id="budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    min="0"
                  />
                </div>
                <div className="option-group">
                  <label htmlFor="days">Days</label>
                  <input
                    type="number"
                    id="days"
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    min="1"
                  />
                </div>
              </div>
              <div className="action-buttons">
                <button className="try-again-btn">Try one more time</button>
                <button className="save-edit-btn">Save / Edit</button>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="bottom-section">
          <div className="left-column">
            <div className="daily-plan">
              <h2>Daily Itinerary</h2>
              {mockItinerary.map((day, index) => (
                <div key={index} className="day-plan">
                  <div className="day-header">
                    <div className="day-title-weather">
                      <h3 className="day-title">Day {day.day}: {day.title}</h3>
                      <div className="day-weather">
                        {day.weather.temp}°C <i className={`fas fa-${day.weather.condition === "Partly Cloudy" ? "cloud-sun" : "sun"}`}></i>
                      </div>
                    </div>
                    <button className="expand-btn" onClick={() => toggleDayExpansion(day.day)}>
                      {expandedDays[day.day] ? '-' : '+'}
                    </button>
                  </div>
                  <p
                    ref={(el) => descriptionRefs.current[day.day] = el}
                    className={`day-description ${expandedDays[day.day] ? 'expanded' : ''}`}
                    title={truncatedDescriptions[day.day] ? day.description : ''}
                  >
                    {day.description}
                  </p>
                  <div className="day-separator"></div>
                  {day.budget && (
                    <div className="day-budget">
                      <span><i className="fas fa-utensils"></i> €{day.budget.food}</span>
                      <span><i className="fas fa-ticket-alt"></i> €{day.budget.activities}</span>
                      <span><i className="fas fa-bus"></i> €{day.budget.transport}</span>
                    </div>
                  )}
                  {expandedDays[day.day] && day.activities && day.activities.length > 0 && (
                    <div className="day-activities">
                      {day.activities.map((activity, actIndex) => (
                        <React.Fragment key={actIndex}>
                          <div className="activity">
                            <i className={`fas fa-${activity.icon}`}></i>
                            <span>{activity.time} - {activity.description}</span>
                          </div>
                          {actIndex < day.activities.length - 1 && (
                            <div className="logistics">
                              <i className="fas fa-arrow-down"></i>
                              <span>10-15 minutes walk</span>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="right-column">
            <div className="map-container">
                <MapComponent />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TravelPlanPageMockup;