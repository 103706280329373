import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/TripCard.css'; // Ensure this CSS file exists

const TripCard = ({ trip }) => {
  const navigate = useNavigate();

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent triggering the card's onClick
    navigate(`/trip/${trip._id}/edit`);
  };

  return (
    <Link to={`/trip/${trip._id}/edit`} className="trip-card-link">
      <div className="trip-card">
        <img
          src={trip.plan.backgroundImage || '/images/default-trip.png'}
          alt={`Trip to ${trip.destinationCity}, ${trip.destinationCountry}`}
          className="trip-image"
        />
        <div className="trip-details">
          <h3>
            {trip.destinationCity}
            <br></br>
            {trip.destinationCountry}
          </h3>
          <p>
            Duration: {trip.duration} {trip.duration === 1 ? 'day' : 'days'}
          </p>
          <p>
            Budget: ${trip.budget ? trip.budget.toLocaleString() : 'Not specified'}
          </p>
          <button onClick={handleEdit} className="edit-button" aria-label={`View or edit trip to ${trip.destinationCity}`}>
            View / Edit Trip
          </button>
        </div>
      </div>
    </Link>
  );
};

export default TripCard;
