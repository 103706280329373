import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="help-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>Privacy Policy</h1>
          <p><strong>Tales.Travel</strong></p>
          <p><em>Last Updated: October 26th, 2024</em></p>

          <p>
            This Privacy Policy describes the privacy practices of Nerdtres, Inc. ("<strong>Tales.Travel</strong>", "<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>") and how we handle personal information that we collect through our website located at <strong>Tales.Travel</strong> (the "<strong>Site</strong>") and through any other applications, websites, or services that we own or control and that link to this Privacy Policy (collectively, the "<strong>Service</strong>"). The Service is designed to be an AI-powered platform for personalized travel planning, recommendations, and support. The Service enables users to create and share travel itineraries and plans, as well as browse and book flights, accommodations, and other travel-related products and services.
          </p>

          <p>
            We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Service.
          </p>

          <p>
            By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access the Service.
          </p>

          <hr />

          <h3>1. Personal Information We Collect</h3>

          <h4>Information You Provide to Us:</h4>
          <ul>
            <li><strong>Contact and Account Information:</strong> Such as your first and last name, email address, phone number, and password. If you create an account or log into the Service using third-party services (e.g., Google, Facebook), we will receive personal information about you as permitted by your profile settings on the third-party service.</li>
            <li><strong>Travel Preferences and Interests:</strong> Including destinations of interest, preferred activities, budget, travel dates, and other information that helps us tailor your experience.</li>
            <li><strong>Reservation Information:</strong> For flights, accommodations, and other travel-related services, including itineraries and booking details.</li>
            <li><strong>Payment Information:</strong> Needed to complete your transactions with us, including name, payment card information, and billing address. Payment transactions are processed by third-party payment processors. We do not store your full payment card information.</li>
            <li><strong>Communications:</strong> Content and metadata of any messages that you send using the Service or to us directly, including when you respond to our surveys or contact us with questions, feedback, or otherwise.</li>
            <li><strong>User Content:</strong> Such as reviews, comments, or other content you choose to post on the Service.</li>
            <li><strong>Other Information:</strong> That you may choose to provide to us.</li>
            <li><strong>Email Marketing Data:</strong> Information related to your subscription preferences and interactions with our email communications.</li>
          </ul>

          <h4>Automatic Data Collection:</h4>
          <p>
            We and our service providers may automatically collect certain information about you, your device, and your use of the Service, including:
          </p>
          <ul>
            <li><strong>Device Information:</strong> Such as your computer's or mobile device's operating system, browser type, device type, IP address, unique device identifiers, and general location information.</li>
            <li><strong>Usage Information:</strong> Such as the pages you view, links you click, the date and time of your visit, the amount of time you spend on each page, and other information about your interaction with the Service.</li>
            <li><strong>Email Interaction Data:</strong> Information about your interactions with our email communications, including opens, clicks, and unsubscribes.</li>
          </ul>

          <h4>Cookies and Similar Technologies:</h4>
          <p>
            We use cookies and similar tracking technologies to collect and use personal information about you, including to serve interest-based advertising. For more information about the types of cookies we use, why we use them, and how you can control cookies, please see our Cookie Policy.
          </p>

          <hr />

          <h3>2. How We Use Personal Information</h3>
          <p>We use personal information for the following purposes or as otherwise described at the time of collection:</p>
          <ul>
            <li><strong>Provide and Improve Our Service:</strong> To operate, maintain, and provide you with the features and functionality of the Service, including to facilitate your travel planning and bookings.</li>
            <li><strong>Personalization:</strong> To personalize your experience on our Service, such as by providing tailored content and recommendations.</li>
            <li><strong>Communications:</strong> To send you administrative information, such as updates, security alerts, and support messages.</li>
            <li><strong>Marketing and Advertising:</strong> To send you marketing communications about products, services, offers, promotions, rewards, and events offered by us and others, and to display advertisements that we believe may be of interest to you.</li>
            <li><strong>Analytics and Research:</strong> To understand and analyze how you use our Service and develop new products, services, features, and functionality.</li>
            <li><strong>Compliance and Protection:</strong> To comply with applicable laws, lawful requests, and legal processes, such as to respond to subpoenas or requests from government authorities; to enforce our terms and conditions; and to protect our rights, privacy, safety, or property, and/or that of you or others.</li>
            <li><strong>Email Marketing:</strong> To manage and send you email communications related to your subscriptions, preferences, and interactions with our email content.</li>
          </ul>

          <hr />

          <h3>3. How We Disclose Personal Information</h3>
          <p>We may disclose your personal information to third parties in the following circumstances:</p>
          <ul>
            <li><strong>Service Providers:</strong> We may share personal information with third-party vendors, service providers, contractors, or agents who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
            <li><strong>Travel Partners:</strong> When you make reservations or bookings, we may share your personal information with the relevant travel providers (e.g., airlines, hotels) to fulfill your booking.</li>
            <li><strong>Business Partners:</strong> We may share personal information with business partners with whom we jointly offer products or services.</li>
            <li><strong>Business Transfers:</strong> We may disclose or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
            <li><strong>Legal Obligations:</strong> We may disclose your information to comply with legal obligations, such as responding to subpoenas, court orders, and legal processes.</li>
            <li><strong>Protection of Rights:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person, or illegal activities.</li>
            <li><strong>With Your Consent:</strong> We may disclose your personal information for any other purpose with your consent.</li>
            <li><strong>Email Service Providers:</strong> We may share your email-related data with trusted third-party service providers to manage and send our email communications in accordance with your subscription preferences.</li>
          </ul>

          <hr />

          <h3>4. Your Privacy Choices</h3>
          <p>You have certain choices regarding your personal information:</p>
          <ul>
            <li><strong>Access and Update:</strong> You may access, update, or correct your account information at any time by logging into your account settings.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Service may become inaccessible or not function properly.</li>
            <li><strong>Marketing Communications:</strong> You may opt out of receiving marketing emails from us by following the unsubscribe instructions included in those emails or by contacting us. Please note that even if you opt out, we may still send you non-marketing communications, such as those about your account or ongoing business relations.</li>
            <li><strong>Do Not Track Signals:</strong> Our Service does not currently respond to "Do Not Track" signals.</li>
          </ul>

          <hr />

          <h3>5. Security</h3>
          <p>
            We take reasonable measures to help protect personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. However, please understand that no security system is impenetrable and we cannot guarantee the absolute security of your personal information.
          </p>

          <hr />

          <h3>6. International Data Transfers</h3>
          <p>
            Your information, including personal information, may be transferred to and maintained on servers and databases located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States, please be aware that we transfer personal information to the United States and process it there.
          </p>

          <hr />

          <h3>7. Children's Privacy</h3>
          <p>
            Our Service is not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.
          </p>

          <hr />

          <h3>8. Third-Party Websites</h3>
          <p>
            The Service may contain links to third-party websites and services that are not owned or controlled by us. We are not responsible for the privacy practices of these third parties. We encourage you to read the privacy policies of each website you visit.
          </p>

          <hr />

          <h3>9. Retention of Personal Information</h3>
          <p>
            We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
          </p>

          <hr />

          <h3>10. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated Privacy Policy on the Service and updating the "Last Updated" date. Your continued use of the Service after the posting of changes constitutes your acceptance of such changes.
          </p>

          <hr />

          <h3>11. Your Rights</h3>
          <p>
            Depending on your jurisdiction, you may have certain rights regarding your personal information, such as the right to access, correct, update, or delete your personal information. To exercise these rights, please contact us using the contact information below.
          </p>

          <hr />

          <h3>12. Governing Law</h3>
          <p>
            This Privacy Policy shall be governed by the laws of the State of Delaware, United States. Any disputes arising out of or relating to this Privacy Policy shall be resolved in the courts of Delaware.
          </p>

          <hr />

          <h3>13. Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy or our privacy practices, or to exercise your rights regarding your personal information, please contact us:
          </p>
          <p><strong>Nerdtres, Inc.</strong></p>
          <p><strong>Email:</strong> <a href="mailto:support@tales.travel">support@tales.travel</a></p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
