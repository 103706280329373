import React from 'react';
import { Link } from 'react-router-dom'; // Use Link for client-side routing
import '../styles/Footer.css'; // Import the Footer CSS

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        {/* Left Section */}
        <div className="footer-left">
          <div className="footer-column">
            <Link to="/about" className="footer-link">About</Link>
            <Link to="/contact" className="footer-link">Contact</Link>
            <Link to="/help" className="footer-link">Help</Link>
          </div>
          <div className="footer-column footer-column-left">
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
          </div>
        </div>

        {/* Right Section */}
        <div className="footer-right">
          <a href="https://instagram.com/talestravelX" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://tiktok.com/@tales.travelX" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-tiktok"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61568650191929" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com/TalesTravelX" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href="https://youtube.com/@TalesTravelX" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/talestravel/" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>

      {/* Divider */}
      <hr className="footer-divider" />

      {/* Bottom Text */}
      <div className="footer-bottom">
        © 2024 Nerdtres, Inc.
      </div>
    </footer>
  );
}

export default Footer;
