import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';
import Navbar from '../components/Navbar';
import CityAutocomplete from '../components/CityAutocomplete';
import AdvancedSettings from '../components/AdvancedSettings'; // Import AdvancedSettings
import { createTrip, subscribeCustomer } from '../utils/serverComm';
import { auth } from '../firebase'; // Updated import
import { v4 as uuidv4 } from 'uuid';
import Footer from '../components/Footer'; // Import Footer
import { trackEvent } from '../analytics';

function LandingPage() {
  const [destination, setDestination] = useState('');
  const [duration, setDuration] = useState('');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState({
    notFirstTime: false,
    persons: 1,
    kids: false,
    budget: '',
    currency: 'USD',
  });
  const navigate = useNavigate();
  const advancedSettingsRef = useRef(null);
  const formRef = useRef(null); // Reference to the destination form
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [consentChecked, setConsentChecked] = useState(false); // New state for checkbox
  const [consentError, setConsentError] = useState(false); // New state for error
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const successModalRef = useRef(null);

  useEffect(() => {
    // Remove anonymous sign-in logic from LandingPage
    // AuthContext now handles authentication

    // Add event listener for clicks outside the modal
    const handleClickOutside = (event) => {
      if (advancedSettingsRef.current && !advancedSettingsRef.current.contains(event.target)) {
        setShowAdvancedSettings(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic frontend validation
    if (!destination.includes(',')) {
      alert('Please select a valid destination from the suggestions.');
      return;
    }

    if (!duration || parseInt(duration) < 1) {
      alert('Please select a valid duration.');
      return;
    }

    console.log('Form submitted');

    const [destinationCity, destinationCountry] = destination.split(', ').map(item => item.trim());
    const tripId = uuidv4();

    // Store trip initiation status in sessionStorage
    sessionStorage.setItem('tripStatus', JSON.stringify({
      tripId,
      status: 'initiated',
    }));

    // Navigate to LoadingPage first
    navigate(`/trip/${tripId}/loading`, { 
      state: { 
        tripId,
        destinationCity, 
        destinationCountry, 
        duration,
        advancedSettings 
      } 
    });

    try {
      // Create trip after navigating to LoadingPage
      const result = await createTrip(tripId, destinationCity, destinationCountry, duration, advancedSettings);
      console.log('Trip created:', result);

      // Update trip status to 'completed'
      sessionStorage.setItem('tripStatus', JSON.stringify({
        tripId,
        status: 'completed',
      }));

      // Navigate to TravelPlanPage after trip is created
      navigate(`/trip/${tripId}/edit`);

      // Optionally, remove tripStatus from sessionStorage if no longer needed
      sessionStorage.removeItem('tripStatus');
    } catch (error) {
      console.error('Error creating trip:', error);
      // Handle error (e.g., show error message to user)

      // Update trip status to 'error'
      sessionStorage.setItem('tripStatus', JSON.stringify({
        tripId,
        status: 'error',
      }));

      navigate('/');
    }
  };

  const toggleAdvancedSettings = (e) => {
    e.preventDefault();
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  const handleApplySettings = () => {
    setShowAdvancedSettings(false);
  };

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = () => {
    trackEvent({
      action: 'click',
      category: 'Button',
      label: 'Craft My Trip Button',
      value: 1,
    });
    // ... other actions
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    if (!consentChecked) {
      setConsentError(true);
      setTimeout(() => setConsentError(false), 2000);
      return;
    }

    console.log('Attempting to subscribe email:', email); // Debug log

    trackEvent({
      action: 'subscribe',
      category: 'Newsletter',
      label: 'Email Subscription',
      value: 1,
    });

    try {
      const response = await subscribeCustomer(email);
      
      if (response.success) {
        e.target.email.value = '';
        setConsentChecked(false);
        setShowSuccessModal(true);
        setTimeout(() => setShowSuccessModal(false), 3000);
      } else {
        setSubscriptionStatus('error');
        alert(`Subscription failed: ${response.message}`);
      }
    } catch (error) {
      setSubscriptionStatus('error');
      console.error('Subscription error:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="landing-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block left-column" ref={formRef}>
          <h1>Plan Your Next Adventure</h1>
          <p>
            Dive into the world of exploration and start crafting unforgettable journeys tailored to your desires.
          </p>
          <form onSubmit={handleSubmit} >
            <div className="form-group">
              <label htmlFor="destination">Destination</label>
              <CityAutocomplete
                value={destination}
                onChange={setDestination}
              />
            </div>
            <div className="form-group">
              <label htmlFor="duration">Duration</label>
              <select
                id="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              >
                <option value="">Select duration</option>
                <option value="1">1 day</option>
                <option value="2">2 days</option>
                <option value="3">3 days</option>
                <option value="5">5 days</option>
                <option value="7">7 days</option>
              </select>
            </div>
            <div className="form-actions">
              <button
                type="button"
                className="advanced-settings-button"
                onClick={toggleAdvancedSettings}
              >
                Advanced settings
              </button>
              <button 
                type="submit" 
                className="cta-button" 
                aria-label="Create travel plan"
                onClick={handleButtonClick}
              >
                <i className="fas fa-paper-plane"></i> Craft My Trip
              </button>
            </div>
          </form>
          {showAdvancedSettings && (
            <div className="advanced-settings-overlay" onClick={() => setShowAdvancedSettings(false)}>
              <div className="advanced-settings-content" ref={advancedSettingsRef} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={() => setShowAdvancedSettings(false)}>
                  <i className="fas fa-times"></i>
                </button>
                <AdvancedSettings
                  settings={advancedSettings}
                  onSettingsChange={setAdvancedSettings}
                  onApply={handleApplySettings}
                />
              </div>
            </div>
          )}
        </div>
        <div className="content-block right-column">
          <div className="image-container">
            <img
              src="/images/paris-illustration.png"
              alt="Destination illustration"
              className="illustration"
            />
          </div>
        </div>
      </main>

      {/* Travel Around the World Section */}
      <section className="travel-around-world">
        <h2>Travel Around the World</h2>
        <div className="travel-images-container">
          <div className="travel-image-wrapper">
            <h3>International Destinations</h3>
            <img src="/images/inter_destin.png" alt="International Destinations" />
          </div>
          <div className="travel-image-wrapper">
            <h3>Domestic Destinations</h3>
            <img src="/images/dom_dest.png" alt="Domestic Destinations" />
          </div>
        </div>
        <p className="travel-description">
          Whether you're dreaming of exploring exotic international landmarks or discovering hidden gems closer to home, 
          we've got you covered. Our AI-powered platform creates personalized itineraries for both worldwide adventures and local escapes.
        </p>
        <button className="cta-button" onClick={scrollToForm}>
          Craft My Trip for Free
        </button>
      </section>

      {/* How Tales.Travel Works Section */}
      <section className="how-it-works">
        <h2>How Tales.Travel Works</h2>
        <div className="steps">
          <div className="step">
            <i className="fas fa-map-marker-alt"></i>
            <h3>Share your travel preferences and choose your destination.</h3>
          </div>
          <div className="step">
            <i className="fas fa-sliders-h"></i>
            <h3>Customize your trip with advanced settings.</h3>
          </div>
          <div className="step">
            <i className="fas fa-clipboard-check"></i>
            <h3>Review and finalize your personalized travel plan.</h3>
          </div>
          <div className="step">
            <i className="fas fa-plane-departure"></i>
            <h3>Enjoy your adventure and share your experiences.</h3>
          </div>
        </div>
        <button className="cta-button" onClick={scrollToForm}>
          Craft My Trip for Free
        </button>
      </section>

      {/* Explore with Us Section */}
      <section className="explore-with-us">
        <h2>Explore with Us</h2>
        <p>
          Join over one million seasoned Tales Travelers. Receive destination guides, travel tips, exclusive interviews, and more, delivered weekly to your inbox.
        </p>
        <form className={`subscription-form ${consentError ? 'consent-error' : ''}`} onSubmit={handleSubscribe}>
          <div className="subscription-wrapper">
            <div className="input-button-group">
              <input 
                type="email" 
                id="email" 
                name="email"
                placeholder="Enter your email" 
                required 
              />
              <button type="submit" className="subscribe-button">
                Subscribe Now
              </button>
            </div>
            <div className="consent-wrapper">
              <label className="consent-label">
                <input 
                  type="checkbox" 
                  id="consent" 
                  checked={consentChecked}
                  onChange={(e) => setConsentChecked(e.target.checked)}
                />
                <span className="consent-text">
                  I agree to receive newsletters, updates, and offers from Tales.Travel
                </span>
              </label>
            </div>
          </div>
        </form>
        {subscriptionStatus === 'success' && (
          <p className="success-message">Thank you for subscribing!</p>
        )}
        {subscriptionStatus === 'error' && (
          <p className="error-message">Subscription failed. Please try again.</p>
        )}
      </section>

      <Footer />
      
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal-overlay" onClick={() => setShowSuccessModal(false)}>
          <div className="success-modal" ref={successModalRef} onClick={(e) => e.stopPropagation()}>
            <button 
              className="close-button" 
              onClick={() => setShowSuccessModal(false)}
            >
              <i className="fas fa-times"></i>
            </button>
            <div className="success-modal-content">
              <i className="fas fa-check-circle success-icon"></i>
              <h3>Thank you for subscribing!</h3>
              <p>Welcome to our community! You'll receive our first newsletter soon.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
