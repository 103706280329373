import axios from 'axios';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';


//Production
//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

//Dev Local
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

if (!API_BASE_URL) {
  throw new Error('REACT_APP_API_BASE_URL is not defined');
}

//Dev Ngrok
//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://secondtestok.ngrok.app/api';


let authStateReady = new Promise((resolve) => {
  onAuthStateChanged(auth, () => {
    resolve();
  });
});

const getAuthToken = async () => {
  // Wait for Firebase Auth state to be ready
  await authStateReady;

  const user = auth.currentUser;
  if (!user) {
    console.error('No user is signed in.');
    return null;
  }

  const token = await user.getIdToken();
  if (process.env.NODE_ENV !== 'production') {
    console.log('Full auth token:', token);
  }
  return token;
};

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(async (config) => {
  const token = await getAuthToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export const createTrip = async (tripId, destinationCity, destinationCountry, duration, advancedSettings) => {
  console.log('Sending createTrip request to server:', { tripId, destinationCity, destinationCountry, duration, advancedSettings });
  try {
    const token = await getAuthToken();
    const response = await api.post(
      '/createTrip',
      { tripId, destinationCity, destinationCountry, duration, advancedSettings },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating trip:', error.response?.data || error.message);
    throw error;
  }
};

export const getTripDetails = async (tripId) => {
  try {
    const response = await api.get(`/getTrip/${tripId}`);
    console.log('Trip details:', response.data);

    // Process the place data and ensure unique id and order fields
    const processedData = {
      ...response.data,
      plan: {
        ...response.data.plan,
        itinerary: response.data.plan.itinerary.map((day, dayIndex) => ({
          ...day,
          day: dayIndex + 1, // Ensure day numbering starts at 1
          activities: day.activities.map((activity, activityIndex) => ({
            ...activity,
            id: activity.id || uuidv4(), // Assign unique id if not present
            order: activity.order || activityIndex + 1, // Assign order if not present
            googlePlaceId: activity.validate ? activity.place_id : 'none',
            location: activity.location || null
          }))
        }))
      }
    };

    return processedData;
  } catch (error) {
    console.error('Error fetching trip details:', error.response?.data || error.message);
    throw error;
  }
};

export const pollTripStatus = async (tripId) => {
  try {
    const response = await api.get(`/getTripStatus/${tripId}`);
    return response.data.status;
  } catch (error) {
    console.error('Error polling trip status:', error.response?.data || error.message);
    throw error;
  }
};

export const getCitySuggestions = async (query) => {
  try {
    const response = await api.get(`/citySuggestions?query=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching city suggestions:', error.response?.data || error.message);
    throw error;
  }
};

export const getFunFacts = async (destinationCity, destinationCountry) => {
  console.log('Fetching fun facts for', destinationCity, destinationCountry);
  try {
    const response = await api.get(
      `/getFunFacts/${encodeURIComponent(destinationCity)}/${encodeURIComponent(destinationCountry)}`
    );
    console.log('Fun facts response:', response.data);
    return response.data.facts;
  } catch (error) {
    console.error('Error fetching fun facts:', error.response?.data || error.message);
    throw error;
  }
};

// Added getTrips function
export const getTrips = async () => {
  try {
    const response = await api.get('/getTrips');
    console.log('Trips fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching trips:', error.response?.data || error.message);
    throw error;
  }
};

export const addDaysToTrip = async (tripId, startDay, numberOfDays) => {
  console.log(`Adding ${numberOfDays} days starting from day ${startDay} to trip ${tripId}`);
  try {
    const token = await getAuthToken();
    const response = await api.post(
      '/addDay',
      { tripId, startDay, numberOfDays },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Added days response:', response.data);
    return response.data.plan;
  } catch (error) {
    console.error('Error adding days to trip:', error.response?.data || error.message);
    throw error;
  }
};

export const updateTripSettings = async (tripId, updatedSettings) => {
  console.log('Sending updateTripSettings request to server:', { tripId, updatedSettings });
  try {
    const token = await getAuthToken();
    const response = await api.patch(
      `/updateTripSettings/${tripId}`,
      { updatedSettings },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating trip settings:', error.response?.data || error.message);
    throw error;
  }
};

// **Updated `updateItinerary` Function**
export const updateItinerary = async (tripId, updatedItinerary) => {
  try {
    // Recalculate global order for all activities
    let globalOrder = 1;
    const processedItinerary = updatedItinerary.map(day => ({
      ...day,
      activities: day.activities.map(activity => ({
        ...activity,
        order: globalOrder++
      }))
    }));

    const token = await getAuthToken();
    const response = await api.patch(
      `/updateItinerary/${tripId}`, 
      { itinerary: processedItinerary },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Itinerary updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating itinerary:', error.response?.data || error.message);
    throw error;
  }
};

export const subscribeCustomer = async (userData) => {
  try {
    // Basic validation before making the API call
    if (!userData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      return { 
        success: false, 
        message: 'Please provide a valid email address' 
      };
    }

    const response = await api.post('/subscribe', userData);
    
    if (response.data && response.data.success) {
      return { success: true };
    } else {
      return { 
        success: false, 
        message: response.data.message || 'Subscription failed' 
      };
    }
  } catch (error) {
    console.error('Error in subscribeCustomer:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred while subscribing' 
    };
  }
};

export const generateChallengeImage = async (prompt, email) => {
  try {
    const response = await api.post('/generateChallengeImage', { prompt, email });
    
    if (response.data.error?.code === 'content_policy_violation') {
      return {
        success: false,
        message: "Your prompt may contain references to an artist's style who passed away less than 100 years ago. Please try a different prompt or artistic style.",
        shouldCountAttempt: false
      };
    }
    
    // Add retry logic for image loading
    if (response.data.success && response.data.imagePath) {
      // Try to preload the image
      const imageLoaded = await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = response.data.imagePath;
      });

      if (!imageLoaded) {
        throw new Error('Failed to load generated image');
      }
    }

    return response.data;
  } catch (error) {
    console.error('Error generating challenge image:', error);
    return {
      success: false,
      message: error.response?.data?.message || 'Failed to generate image',
      shouldCountAttempt: true
    };
  }
};
