import React from 'react';
import Switch from 'react-switch';
import '../styles/AdvancedSettings.css';
import { trackEvent } from '../analytics';

const AdvancedSettings = ({ settings, onSettingsChange, onApply }) => {
  const handleChange = (key, value) => {
    // For budget, ensure the value is not negative
    if (key === 'budget') {
      value = Math.max(0, Number(value));
    }
    onSettingsChange({ ...settings, [key]: value });

    trackEvent({
      action: `change_${key}`,
      category: 'AdvancedSettings',
      label: `Advanced Setting Changed: ${key}`,
      value: typeof value === 'boolean' ? (value ? 1 : 0) : value,
    });
  };

  const handleApply = () => {
    if (onApply) {
      onApply();
      trackEvent({
        action: 'apply_settings',
        category: 'AdvancedSettings',
        label: 'Advanced Settings Applied',
        value: 1,
      });
    }
  };

  const handlePersonClick = (num) => {
    handleChange('persons', num);
    trackEvent({
      action: 'select_persons',
      category: 'AdvancedSettings',
      label: `Persons Selected: ${num}`,
      value: typeof num === 'number' ? num : 5,
    });
  };

  return (
    <div className="advanced-settings">
      <h2>Advanced Settings</h2>
      <div className="setting-group">
        <label className="switch-label">
          <span>Going there NOT first time</span>
          <div className="toggle-line"></div>
          <Switch
            checked={settings.notFirstTime}
            onChange={(checked) => handleChange('notFirstTime', checked)}
            onColor="#4CAF50"
            offColor="#7f7f7f"
            height={24}
            width={48}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </label>
      </div>
      <div className="setting-group">
        <div className="switch-label">
          <span>Persons:</span>
          <div className="toggle-line"></div>
          <div className="person-buttons">
            {[1, 2, 3, 4, '5+'].map((num) => (
              <button
                key={num}
                className={settings.persons === num ? 'active' : ''}
                onClick={() => handlePersonClick(num)}
              >
                {num}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="setting-group">
        <label className="switch-label">
          <span>Kids</span>
          <div className="toggle-line"></div>
          <Switch
            checked={settings.kids}
            onChange={(checked) => handleChange('kids', checked)}
            onColor="#4CAF50"
            offColor="#7f7f7f"
            height={24}
            width={48}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </label>
      </div>
      <div className="setting-group">
        <label className="switch-label">
          <span>Travel Support*</span>
          <div className="toggle-line"></div>
          <Switch
            checked={settings.travelSupport}
            onChange={(checked) => handleChange('travelSupport', checked)}
            onColor="#4CAF50"
            offColor="#7f7f7f"
            height={24}
            width={48}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </label>
      </div>
      <div className="setting-group">
        <label className="budget-label">Budget:</label>
        <div className="budget-input">
          <input
            type="number"
            value={settings.budget}
            onChange={(e) => {
              handleChange('budget', e.target.value);
              trackEvent({
                action: 'change_budget_value',
                category: 'AdvancedSettings',
                label: `Budget Changed to: ${e.target.value}`,
                value: Number(e.target.value),
              });
            }}
            placeholder="Enter budget"
            min="0"
          />
          <select
            value={settings.currency}
            onChange={(e) => {
              handleChange('currency', e.target.value);
              trackEvent({
                action: 'change_currency',
                category: 'AdvancedSettings',
                label: `Currency Changed to: ${e.target.value}`,
                value: e.target.value,
              });
            }}
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="JPY">JPY</option>
          </select>
        </div>
      </div>
      <div className="apply-button-container">
        <button className="apply-button" onClick={handleApply}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default AdvancedSettings;
