import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/TermsOfService.css';

function TermsOfService() {
  return (
    <div className="help-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>Tales.Travel - Terms & Conditions</h1>
          <p>
            Welcome to the website <a href="https://tales.travel/">https://tales.travel/</a> (the "Website") operated by Nerdtres, Inc., located at Delaware, USA ("Tales.Travel"). The purpose of this Website is to assist you in crafting unforgettable journeys tailored to your desires (hereinafter, the "Users").
          </p>
          <p>
            These terms and conditions apply exclusively to the relationship between Tales.Travel and its Users.
          </p>
          <p>
            <strong>BY ACCESSING OR USING THE WEBSITE AND SERVICES (AS DEFINED BELOW), USERS AGREE THAT THEY HAVE READ AND UNDERSTOOD THE TERMS SET FORTH IN THESE TERMS OF USE, INCLUDING THE PRIVACY POLICY (COLLECTIVELY, THE "TERMS"), AND USERS AGREE TO BE BOUND BY THEM AND TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS REGARDING THE USE OF THIS WEBSITE AND/OR SERVICES. IF USERS DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE THIS WEBSITE AND/OR ANY OF THE SERVICES PROVIDED BY TALES.TRAVEL.</strong>
          </p>
          <p>
            Tales.Travel shall never be understood as a tour operator, a travel agency, intermediary, or a touristic service provider and is not subject to the application of any governing organized travel contracts and travel agency contracts. Users shall refrain from all acts/communications that state otherwise or could give such impression towards both Users and third parties. The Services are not aimed nor directed to underage children.
          </p>
          <p><strong>Date of Last Revision: October 26th, 2024</strong></p>
          <hr />

          <h2>1. THE SERVICES</h2>

          <h3>1.1 Description of Services</h3>
          <p>
            Tales.Travel offers a Website through which Users can create personalized travel plans free of charge. In particular, Tales.Travel will collaborate with Users to generate travel plans tailored to the User's desires (the "Services"). Specifically, Tales.Travel will assist Users in:
          </p>
          <ul>
            <li><strong>Activities</strong>: Users can explore and select various activities available at their chosen destination. Tales.Travel will organize these activities into an optimized itinerary based on the User's preferences, location, and allocated budget.</li>
            <li><strong>Accommodations</strong>: Users may input their accommodation details so that the itinerary can be tailored accordingly.</li>
            <li><strong>Meals</strong>: Users can organize meals according to their tastes and budget. Tales.Travel may suggest different restaurants based on the User's preferences and dietary restrictions.</li>
            <li><strong>Email Communications:</strong> Users will receive email notifications and updates related to their subscriptions, preferences, and interactions with our email content.</li>
          </ul>

          <h3>1.2 Complementary Services</h3>
          <h4>1.2.1 Social Media Sharing</h4>
          <p>
            Users have the option to share their travel plans or destinations through social media platforms. By sharing content, Users warrant that they have the necessary rights to do so.
          </p>
          <h4>1.2.2 Community Inspiration</h4>
          <p>
            Users may choose to share their travel plans with third parties within the Tales.Travel community, inspiring others with their journeys.
          </p>

          <h3>1.3 User Responsibility</h3>
          <p>
            Users understand and accept that they are responsible for all decisions made regarding their travel plans. Tales.Travel provides suggestions based on User input but holds no liability for the choices Users make.
          </p>

          <h3>1.4 Service Limitations</h3>
          <p>
            The collaboration in organizing the trip may be limited to a certain number of days, as determined by Tales.Travel.
          </p>
          <hr />

          <h2>2. ACCESS AND REGISTRATION</h2>

          <h3>2.1 Account Creation</h3>
          <p>
            To use the Services, Users must complete the following steps:
          </p>
          <ul>
            <li>Register by providing accurate and complete information.</li>
            <li>Agree to these Terms and Conditions.</li>
            <li>Agree to the Privacy Policy.</li>
            <li>Agree to the Cookies Policy.</li>
          </ul>

          <h3>2.2 User Representations</h3>
          <p>
            Users warrant that all information provided is true, accurate, and complete. Users agree to keep their account information updated.
          </p>

          <h3>2.3 Account Security</h3>
          <p>
            Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. Users must immediately inform Tales.Travel at <a href="mailto:support@tales.travel">support@tales.travel</a> if their account is compromised.
          </p>

          <h3>2.4 Eligibility</h3>
          <p>
            The Services are intended for Users who are at least 18 years old. By using the Services, Users represent that they meet this age requirement.
          </p>
          <hr />

          <h2>3. BOOKING PROCESS</h2>

          <h3>3.1 Third-Party Bookings</h3>
          <p>
            Tales.Travel may provide links or referrals to third-party websites or platforms for booking activities, accommodations, or meals. Users understand that any bookings are made directly with these third parties, and Tales.Travel is not responsible for such transactions.
          </p>

          <h3>3.2 Cancellations and Modifications</h3>
          <p>
            Users must handle any reservations, cancellations, or modifications directly with the third-party providers. Tales.Travel is not responsible for the reservation or cancellation of any activities, accommodations, or meals.
          </p>
          <hr />

          <h2>4. WARRANTIES AND LIMITATION OF LIABILITY</h2>

          <h3>4.1 Service Provision</h3>
          <p>
            Tales.Travel provides the Website and Services to assist Users in optimizing their travel plans but does not guarantee the availability, reliability, or suitability of any third-party services.
          </p>

          <h3>4.2 User Acknowledgment</h3>
          <p>
            Users acknowledge that they use the Services at their own risk. Tales.Travel shall not be liable for any loss of profits or damages arising from the use of the Services.
          </p>

          <h3>4.3 No Agency</h3>
          <p>
            Tales.Travel is not a tour operator, travel agent, or service provider and does not represent or warrant any third-party services. Users are solely responsible for their interactions with third-party providers.
          </p>

          <h3>4.4 Limitation of Liability</h3>
          <p>
            In the unlikely event that Tales.Travel is found liable for any loss or damage arising out of the use of the Website or Services, Tales.Travel's total liability shall not exceed USD 1,000.
          </p>
          <hr />

          <h2>5. USER OBLIGATIONS</h2>

          <h3>5.1 Compliance with Laws</h3>
          <p>
            Users agree to use the Website and Services in accordance with all applicable laws and regulations, as well as with principles of good faith and public order.
          </p>

          <h3>5.2 Prohibited Conduct</h3>
          <p>
            Users shall not:
          </p>
          <ul>
            <li>Use the Website for unlawful purposes.</li>
            <li>Infringe on Tales.Travel's or any third party's intellectual property rights.</li>
            <li>Introduce viruses or harmful code.</li>
            <li>Attempt to gain unauthorized access to other Users' accounts.</li>
            <li>Use the Website to insult, defame, intimidate, or harass others.</li>
          </ul>

          <h3>5.3 Reporting Violations</h3>
          <p>
            Users may report any abuse or violations of these Terms by contacting Tales.Travel at <a href="mailto:support@tales.travel">support@tales.travel</a>. Tales.Travel reserves the right to take appropriate action, including suspension or termination of User accounts.
          </p>
          <hr />

          <h2>6. INDEMNIFICATION</h2>

          <h3>6.1 User Indemnity</h3>
          <p>
            Users agree to indemnify and hold harmless Tales.Travel and its affiliates from any claims, damages, or expenses arising out of:
          </p>
          <ul>
            <li>Any breach of these Terms by the User.</li>
            <li>Any violation of applicable laws.</li>
            <li>Any interactions or transactions between the User and third-party providers.</li>
            <li>Any content provided by the User that infringes on third-party rights.</li>
          </ul>
          <hr />

          <h2>7. INTELLECTUAL PROPERTY</h2>

          <h3>7.1 Ownership</h3>
          <p>
            All intellectual property rights in the Website and Services, including but not limited to text, graphics, logos, and software, are owned by or licensed to Tales.Travel.
          </p>

          <h3>7.2 License to Users</h3>
          <p>
            Tales.Travel grants Users a non-exclusive, non-transferable, royalty-free license to use the Website and Services for personal, non-commercial purposes.
          </p>

          <h3>7.3 User Content</h3>
          <p>
            By sharing content on the Website, Users grant Tales.Travel a worldwide, royalty-free license to use, reproduce, distribute, and display such content in connection with the Services.
          </p>

          <h3>7.4 Prohibited Actions</h3>
          <p>
            Users shall not copy, modify, distribute, or reverse engineer any part of the Website or Services without prior written consent from Tales.Travel.
          </p>
          <hr />

          <h2>8. DATA PROTECTION</h2>

          <h3>8.1 Privacy Policy</h3>
          <p>
            Tales.Travel respects Users' privacy and handles personal data in accordance with its Privacy Policy.
          </p>

          <h3>8.2 Data Sharing</h3>
          <p>
            Except as required to provide the Services, Tales.Travel will not disclose Users' personal data to third parties without consent. This includes sharing information with trusted third-party service providers to manage email communications based on Users' subscription preferences.
          </p>
          <hr />

          <h2>9. PROHIBITED ACTIVITIES</h2>
          <p>
            Users may not:
          </p>
          <ul>
            <li>Copy or modify any part of the Website or Services.</li>
            <li>Interfere with the operation of the Website or Services.</li>
            <li>Collect or harvest personal data about other Users.</li>
            <li>Use the Website to defame, harass, or violate the rights of others.</li>
            <li>Upload or transmit harmful or illegal content.</li>
          </ul>
          <hr />

          <h2>10. MODIFICATIONS TO THE TERMS</h2>

          <h3>10.1 Updates</h3>
          <p>
            Tales.Travel reserves the right to modify these Terms at any time to reflect changes in our Services or applicable laws. Users will be notified of significant changes through the Website or via email at least thirty (30) days prior to the changes taking effect.
          </p>

          <h3>10.2 Continued Use</h3>
          <p>
            Continued use of the Website and Services after any changes signifies acceptance of the new Terms.
          </p>
          <hr />

          <h2>11. EXTERNAL WEBSITES</h2>

          <h3>11.1 Third-Party Links</h3>
          <p>
            The Website may contain links to external websites or services ("External Websites"). Tales.Travel is not responsible for the content or services provided by these External Websites.
          </p>

          <h3>11.2 User Responsibility</h3>
          <p>
            Users access External Websites at their own risk and should review the terms and policies of those sites. Tales.Travel shall not be liable for any damages arising from the use of External Websites.
          </p>
          <hr />

          <h2>12. CLAIMS AND QUESTIONS</h2>
          <p>
            Users may contact Tales.Travel with any questions, concerns, or to report violations at <a href="mailto:support@tales.travel">support@tales.travel</a>.
          </p>
          <hr />

          <h2>13. FINAL PROVISIONS</h2>

          <h3>13.1 Severability</h3>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
          </p>

          <h3>13.2 Governing Law</h3>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, USA, without regard to its conflict of law provisions.
          </p>

          <h3>13.3 Dispute Resolution</h3>
          <p>
            Any disputes arising from these Terms or the use of the Services shall be resolved through amicable negotiations. If not resolved, disputes shall be submitted to the exclusive jurisdiction of the competent courts of the State of Delaware, USA.
          </p>
          <hr />

          <p><strong>Date of these Terms and Conditions: October 26th, 2024</strong></p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default TermsOfService;
