import React, { useRef, useEffect, useState } from 'react';
import '../styles/FlappyBird404.css';

const FlappyBird404 = () => {
  const canvasRef = useRef(null);
  const scoreRef = useRef(0);
  const gameOverRef = useRef(false);
  const pipesRef = useRef([]);
  const pipeIntervalRef = useRef(null);
  const animationFrameRef = useRef(null);
  
  const [renderScore, setRenderScore] = useState(0);
  const [renderGameOver, setRenderGameOver] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (!context) {
      console.error('Canvas context not available');
      return;
    }

    canvas.width = 480;
    canvas.height = 640;

    const width = canvas.width;
    const height = canvas.height;

    let frames = 0;
    const DEGREE = Math.PI / 180;

    const bird = {
      x: 50,
      y: height / 2 - 50, // Adjusted initial position
      size: 25, // Increased size for visibility
      gravity: 0.6,
      jump: -12,
      velocity: 0,
      rotation: 0,
      flap() {
        this.velocity = this.jump;
      },
      draw() {
        // Draw travel-themed character (e.g., airplane)
        context.fillStyle = '#6200ea'; // Faded purple from LandingPage
        context.beginPath();
        context.moveTo(this.x, this.y - this.size / 2);
        context.lineTo(this.x + this.size, this.y);
        context.lineTo(this.x, this.y + this.size / 2);
        context.closePath();
        context.fill();

        // Optional: Add window or details to the airplane
        context.fillStyle = '#ffffff';
        context.beginPath();
        context.arc(this.x + this.size / 4, this.y, 4, 0, 2 * Math.PI);
        context.fill();
      },
      update() {
        this.velocity += this.gravity;
        this.y += this.velocity;
        
        this.rotation = this.velocity * 2;

        if (this.y < this.size / 2) {
          this.y = this.size / 2;
          this.velocity = 0;
        }

        if (this.y + this.size / 2 >= height) {
          this.y = height - this.size / 2;
          endGame();
        }
      }
    };

    const pipeWidth = 60;
    const pipeGap = 200; // Reduced gap for classic feel
    const pipeSpeed = 2;

    const createPipe = () => {
      const topPipeHeight = Math.floor(Math.random() * (height / 3)) + 20; // Adjusted
      const bottomPipeY = topPipeHeight + pipeGap;
      pipesRef.current.push({
        top: { x: width, y: 0, width: pipeWidth, height: topPipeHeight },
        bottom: { x: width, y: bottomPipeY, width: pipeWidth, height: height - bottomPipeY },
        passed: false
      });
    };

    const drawPipes = () => {
      pipesRef.current.forEach(pipe => {
        // Draw travel-themed pipes (e.g., skyscrapers)
        context.fillStyle = '#5E35B1'; // Faded purple
        context.fillRect(pipe.top.x, pipe.top.y, pipe.top.width, pipe.top.height);
        context.fillRect(pipe.bottom.x, pipe.bottom.y, pipe.bottom.width, pipe.bottom.height);
        
        // Add windows to skyscrapers
        context.fillStyle = '#FFC107'; // Soft yellow for windows
        const windowSize = 5;
        for (let y = pipe.top.y + 10; y < pipe.top.height; y += 20) {
          for (let x = pipe.top.x + 10; x < pipe.top.x + pipe.top.width - 10; x += 15) {
            context.fillRect(x, y, windowSize, windowSize);
          }
        }
        for (let y = pipe.bottom.y + 10; y < pipe.bottom.height; y += 20) {
          for (let x = pipe.bottom.x + 10; x < pipe.bottom.x + pipe.bottom.width - 10; x += 15) {
            context.fillRect(x, y, windowSize, windowSize);
          }
        }
      });
    };

    const updatePipes = () => {
      pipesRef.current.forEach(pipe => {
        // Move pipes to the left
        pipe.top.x -= pipeSpeed;
        pipe.bottom.x -= pipeSpeed;

        // Collision Detection
        if (
          squareCircleCollision(bird.x, bird.y, bird.size / 2, pipe.top) ||
          squareCircleCollision(bird.x, bird.y, bird.size / 2, pipe.bottom)
        ) {
          endGame();
        }

        // Score Update
        if (!pipe.passed && pipe.top.x + pipeWidth / 2 < bird.x) {
          pipe.passed = true;
          scoreRef.current += 1;
          setRenderScore(scoreRef.current);
        }
      });

      // Remove pipes that have gone off-screen
      if (pipesRef.current.length && pipesRef.current[0].top.x + pipeWidth < 0) {
        pipesRef.current.shift();
      }
    };

    const drawScore = () => {
      context.fillStyle = '#6200ea'; // Faded purple
      context.font = 'bold 28px "Press Start 2P"'; // 8-bit styled font
      context.textAlign = 'center';
      context.fillText(`${scoreRef.current}`, width / 2, 50);
    };

    const drawGameOver = () => {
      context.fillStyle = 'rgba(0, 0, 0, 0.7)';
      context.fillRect(0, 0, width, height);

      context.fillStyle = '#FFFFFF'; // White text for contrast
      context.font = 'bold 32px "Press Start 2P"';
      context.textAlign = 'center';
      context.fillText('GAME OVER', width / 2, height / 2 - 40);
      
      context.font = 'bold 20px "Press Start 2P"';
      context.fillText('Press Space or Click to Retry', width / 2, height / 2 + 10);
      
      context.font = 'bold 24px "Press Start 2P"';
      context.fillText(`Score: ${scoreRef.current}`, width / 2, height / 2 - 80);
    };

    const endGame = () => {
      gameOverRef.current = true;
      setRenderGameOver(true);
      cancelAnimationFrame(animationFrameRef.current);
    };

    const resetGame = () => {
      scoreRef.current = 0;
      setRenderScore(0);
      gameOverRef.current = false;
      setRenderGameOver(false);
      bird.y = height / 2 - 50; // Reset to initial position
      bird.velocity = 0;
      pipesRef.current = [];
      frames = 0;
      if (pipeIntervalRef.current) {
        clearInterval(pipeIntervalRef.current);
      }
      createPipe(); // Create first pipe immediately
      pipeIntervalRef.current = setInterval(createPipe, 1500);
      animate();
    };

    const animate = () => {
      if (gameOverRef.current) {
        drawGameOver();
        return;
      }

      context.clearRect(0, 0, width, height);

      bird.draw();
      bird.update();

      updatePipes();
      drawPipes();

      drawScore();

      frames++;
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    const flap = () => {
      if (!gameOverRef.current) {
        bird.flap();
      } else {
        resetGame();
      }
    };

    // Define named event handlers
    const handleKeyDown = (e) => {
      if (e.code === 'Space') {
        e.preventDefault();
        flap();
      }
    };

    const handleClick = () => {
      flap();
    };

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('click', handleClick);

    // Create the first pipe immediately
    createPipe();
    pipeIntervalRef.current = setInterval(createPipe, 1500);
    animate();

    // Cleanup event listeners and intervals on unmount or game over
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClick);
      if (pipeIntervalRef.current) {
        clearInterval(pipeIntervalRef.current);
      }
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, []); // Ensure dependencies are correct

  // Helper function for Square-Rectangle Collision Detection
  const squareCircleCollision = (circleX, circleY, radius, rect) => {
    const distX = Math.abs(circleX - (rect.x + rect.width / 2));
    const distY = Math.abs(circleY - (rect.y + rect.height / 2));

    if (distX > rect.width / 2 + radius) {
      return false;
    }
    if (distY > rect.height / 2 + radius) {
      return false;
    }

    if (distX <= rect.width / 2) {
      return true;
    }
    if (distY <= rect.height / 2) {
      return true;
    }

    const dx = distX - rect.width / 2;
    const dy = distY - rect.height / 2;
    return dx * dx + dy * dy <= radius * radius;
  };

  return (
    <div className="flappy-bird-container">
      <canvas ref={canvasRef} />
      {renderGameOver && (
        <div className="game-over-overlay">
          {/* Additional game over UI can be added here if needed */}
        </div>
      )}
      {/* Optionally display the score outside the canvas */}
      <div className="score-display">Score: {renderScore}</div>
    </div>
  );
};

export default FlappyBird404;
