import React, { useState, useContext } from 'react';
import { auth } from '../firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import '../styles/SignIn.css';
import { AuthContext } from '../contexts/AuthContext';
import PasswordStrengthMeter from '../components/PasswordStrengthMeter';
import { CSSTransition } from 'react-transition-group';

function SignIn({ onClose }) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { currentUser } = useContext(AuthContext);

  const toggleRegistering = () => {
    setIsRegistering(!isRegistering);
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasCapitalLetter = /[A-Z]/.test(password);
    return minLength && hasSpecialChar && hasCapitalLetter;
  };

  const handleEmailPasswordAuth = (e) => {
    e.preventDefault();
    setError('');

    if (isRegistering && password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (isRegistering && !validatePassword(password)) {
      setError('Password must be at least 8 characters long, include a special character, and a capital letter');
      return;
    }

    if (isRegistering) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          onClose();
        })
        .catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            setError('An account with this email already exists');
          } else {
            setError('An error occurred during registration. Please try again.');
          }
        });
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          onClose();
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-credential' || error.code === 'auth/wrong-password') {
            setError('Incorrect email or password');
          } else if (error.code === 'auth/user-not-found') {
            setError('No account found with this email');
          } else {
            setError('An error occurred during sign in. Please try again.');
          }
        });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      onClose();
    } catch (error) {
      console.error('Error with Google Sign-In:', error);
      setError('An error occurred during Google Sign-In. Please try again.');
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-header">
        <h2>
          <span
            className={`auth-option ${!isRegistering ? 'active' : ''}`}
            onClick={() => setIsRegistering(false)}
          >
            Sign In
          </span>
          {' / '}
          <span
            className={`auth-option ${isRegistering ? 'active' : ''}`}
            onClick={() => setIsRegistering(true)}
          >
            Register
          </span>
        </h2>
      </div>
      <form onSubmit={handleEmailPasswordAuth}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordStrength(e.target.value.length);
            }}
            required
          />
          <CSSTransition
            in={isRegistering}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="password-strength-container">
              <div className="password-rules">
                Min 8 chars, 1 uppercase, 1 number, 1 special char
              </div>
              <PasswordStrengthMeter password={password} />
            </div>
          </CSSTransition>
        </div>
        <CSSTransition
          in={isRegistering}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </CSSTransition>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="cta-button signin-cta-button">
          {isRegistering ? 'Register' : 'Sign In'}
        </button>
      </form>
      <div className="or-divider">
        <span>OR</span>
      </div>
      <button onClick={handleGoogleSignIn} className="google-button">
        <img
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google logo"
          className="google-logo"
        />
        Sign in with Google
      </button>
    </div>
  );
}

export default SignIn;
