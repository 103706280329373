// MapComponent.js

import React, { useCallback, useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { trackEvent } from '../analytics';
import '../styles/MapComponent.css';

const MapComponent = ({ destination, itinerary }) => {
  console.log('MapComponent rendered with destination:', destination);
  const [map, setMap] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  // Derive places from itinerary
  const [mapPlaces, setMapPlaces] = useState([]);

  useEffect(() => {
    if (!itinerary) return;

    // Prepare places for the map in the order of the itinerary
    const places = [];
    itinerary.forEach((day) => {
      day.activities
        .sort((a, b) => a.order - b.order) // Ensure activities are sorted by order
        .forEach((activity) => {
          if (activity.validate && activity.placeId !== 'none') {
            places.push({
              name: activity.place_name,
              placeId: activity.placeId,
              location: activity.location,
              order: activity.order,
            });
          }
        });
    });
    
    setMapPlaces(places);

    // If map exists, update bounds
    if (map && places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach(place => {
        if (place.location) {
          bounds.extend(place.location);
        }
      });
      map.fitBounds(bounds);
    }
  }, [itinerary, map]); // Add map to dependencies

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onLoad = useCallback(function callback(mapInstance) {
    if (mapPlaces.length === 0) return;
    const bounds = new window.google.maps.LatLngBounds();
    mapPlaces.forEach(place => {
      if (place.location) {
        bounds.extend(place.location);
      }
    });
    mapInstance.fitBounds(bounds);
    setMap(mapInstance);
  }, [mapPlaces]);

  const onUnmount = useCallback(function callback(mapInstance) {
    setMap(null);
  }, []);

  const handleMarkerClick = (place) => {
    setSelectedPlace(place);
    
    trackEvent({
      action: 'click',
      category: 'MapComponent',
      label: `Marker Click: ${place.name}`,
      value: 1,
    });
  };

  const handleInfoWindowClose = () => {
    if (selectedPlace) {
      trackEvent({
        action: 'close',
        category: 'MapComponent',
        label: `InfoWindow Close: ${selectedPlace.name}`,
        value: 1,
      });
    }
    setSelectedPlace(null);
  };

  const handleMapIdle = () => {
    if (map) {
      const center = map.getCenter();
      const zoom = map.getZoom();
      
      trackEvent({
        action: 'map_idle',
        category: 'MapComponent',
        label: `Map moved to center: (${center.lat()}, ${center.lng()}) with zoom level: ${zoom}`,
        value: zoom,
      });
    }
  };

  const handleZoomChanged = () => {
    if (map) {
      const zoom = map.getZoom();
      
      trackEvent({
        action: 'zoom_change',
        category: 'MapComponent',
        label: `Map zoom changed to level: ${zoom}`,
        value: zoom,
      });
    }
  };

  const getMarkerColor = (index) => {
    const colors = ['#6200ea', '#03dac6', '#b00020', '#ff0266', '#ff5722']; // Extended color array
    return colors[index % colors.length];
  };

  const getMarkerIcon = (color) => ({
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 14,
  });

  const mapContainerStyle = {
    width: '100%',
    height: windowSize.width <= 768 ? '300px' : '100vh',
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onIdle={handleMapIdle}
      onZoomChanged={handleZoomChanged}
    >
      {mapPlaces.map((place) => (
        place.location ? (
          <Marker
            key={place.placeId}
            position={place.location}
            onClick={() => handleMarkerClick(place)}
            label={{
              text: place.order.toString(),
              color: 'white',
              fontSize: '14px',
              fontWeight: 'bold'
            }}
            icon={getMarkerIcon(getMarkerColor(place.order - 1))}
          />
        ) : null
      ))}
      {selectedPlace && (
        <InfoWindow
          position={selectedPlace.location}
          onCloseClick={handleInfoWindowClose}
        >
          <div>
            <h3>{selectedPlace.name}</h3>
            <p>{selectedPlace.address}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default MapComponent;