import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/CookiesPolicy.css';

function CookiesPolicy() {
  return (
    <div className="cookies-policy-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>Cookie Policy</h1>
          <p><strong>Tales.Travel</strong></p>
          <p><em>Last Updated: October 26th, 2024</em></p>

          <p>
            This Cookie Policy explains how Nerdtres, Inc. ("<strong>Tales.Travel</strong>", "<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>") uses cookies and similar technologies to recognize you when you visit our website at <strong>Tales.Travel</strong> (the "<strong>Site</strong>"). It explains what these technologies are and why we use them, as well as your rights to control their use.
          </p>

          <hr />

          <h3>1. What are Cookies?</h3>
          <p>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </p>

          <h3>2. How We Use Cookies</h3>
          <p>We use cookies for the following purposes:</p>
          <ul>
            <li><strong>Essential Cookies:</strong> These cookies are necessary for the proper functioning of our Site. They enable basic functions like page navigation and access to secure areas of the Site.</li>
            <li><strong>Performance and Analytics Cookies:</strong> These cookies collect information about how visitors use our Site, such as which pages are visited most often. This helps us improve the performance and user experience of our Site.</li>
            <li><strong>Functionality Cookies:</strong> These cookies allow our Site to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features.</li>
            <li><strong>Advertising and Marketing Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and to help measure the effectiveness of advertising campaigns.</li>
          </ul>

          <h3>3. Third-Party Cookies</h3>
          <p>
            In addition to our own cookies, we may also use various third-party cookies to report usage statistics, deliver advertisements, and more. These third parties may collect information about your online activities over time and across different websites when you use our Site.
          </p>

          <h3>4. Your Choices Regarding Cookies</h3>
          <p>You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our Site, but your ability to use some features or areas of our Site may be limited.</p>

          <p>
            To learn more about how to manage cookies, please visit <a href="https://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">All About Cookies</a>.
          </p>

          <hr />

          <h3>5. Changes to This Cookie Policy</h3>
          <p>
            We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CookiesPolicy;
