import React, { useState, useEffect, useRef } from 'react';
import { getCitySuggestions } from '../utils/serverComm';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

function CityAutocomplete({ value, onChange }) {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const cacheRef = useRef({});

  const fetchSuggestions = debounce(async (input) => {
    if (cacheRef.current[input]) {
      setSuggestions(cacheRef.current[input]);
    } else {
      try {
        const results = await getCitySuggestions(input);
        cacheRef.current[input] = results;
        setSuggestions(results);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    }
  }, 300); // 300ms debounce

  useEffect(() => {
    if (value.length >= 3 && !selectedCity) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  }, [value, selectedCity]);

  const handleSuggestionClick = (suggestion) => {
    setSelectedCity(suggestion);
    onChange(suggestion);
    setShowSuggestions(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
    setSelectedCity(null);
  };

  const handleClearSelection = () => {
    setSelectedCity(null);
    onChange('');
  };

  return (
    <div className="city-autocomplete">
      <div className="input-wrapper">
        <input
          type="text"
          value={value}
          onChange={handleInputChange}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
          placeholder="Enter your destination"
          required
          readOnly={!!selectedCity}
        />
        {value && (
          <button
            className={`clear-button ${selectedCity ? 'active' : ''}`}
            onClick={handleClearSelection}
            type="button"
          >
            ×
          </button>
        )}
      </div>
      {showSuggestions && suggestions.length > 0 && !selectedCity && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

CityAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CityAutocomplete;
