import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/About.css';

function About() {
  return (
    <div className="about-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>About Us</h1>
          <p>
            Welcome to Tales.Travels! We are dedicated to helping you craft unforgettable journeys tailored to your desires. Our mission is to provide personalized travel plans that make your adventures seamless and memorable.
          </p>
          <p>
            Our AI-powered platform combines cutting-edge technology with deep travel expertise to create unique itineraries that match your preferences, whether you're exploring exotic international landmarks or discovering hidden gems closer to home.
          </p>
          <p>
            With Tales.Travels, you'll experience:
          </p>
          <ul>
            <li>Personalized travel recommendations</li>
            <li>Expert local insights</li>
            <li>Seamless trip planning</li>
            <li>24/7 travel support</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default About;
