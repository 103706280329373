import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/Help.css';

function Help() {
  return (
    <div className="help-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>Need help? We're here for you.</h1>
          <p>
            Check out our FAQs or <a href="/contact">contact us</a> for additional support.
          </p>
          <hr />
          
          <div className="faq-section">
            <h3>### What is Tales.Travel?</h3>
            <p>
              We believe that planning your journey should be as enjoyable as the adventure itself. That's why we created Tales.Travel, a personalized platform that helps you discover, plan, and organize any experience—whether abroad or close to home—all in one place. Using advanced AI technology, we bring the world to you and empower you to experience it your way.
            </p>
            
            <h3>### Who is behind Tales.Travel?</h3>
            <p>
              Our team is composed of passionate travelers and technology enthusiasts dedicated to making travel planning seamless and personalized for everyone.
            </p>
            
            <h3>### What can I do using Tales.Travel?</h3>
            <p>
              Tales.Travel assists you through every phase of your travel experience: inspiration and discovery, planning and booking, and enjoying and sharing your stories.
            </p>
            <ul>
              <li><strong>Discover</strong>: Ask us anything travel-related. We'll search countless data sources and, in seconds, provide travel experiences tailored to your unique preferences.</li>
              <li><strong>Plan</strong>: We offer interactive, customizable itineraries. You can also explore itineraries shared by other travelers and incorporate their suggestions into your own plans.</li>
              <li><strong>Collaborate</strong>: Share your itinerary with friends or invite them to join in the planning process.</li>
              <li><strong>Book</strong>: When you're ready, you can book everything you need—transportation, accommodations, restaurants, and activities—and keep track of it all in one place.</li>
              <li><strong>Experience</strong>: While traveling, receive real-time recommendations for things to see and do.</li>
              <li><strong>Share</strong>: After your trip, document your journey and share your experiences with others.</li>
            </ul>
            
            <h3>### Where does the information on Tales.Travel come from?</h3>
            <p>
              We collaborate with trusted travel industry partners to provide accurate and up-to-date information, as well as options for bookings and reservations. If you believe we're missing content or notice any issues with the quality of our information, please let us know.
            </p>
            
            <h3>### How do I make my Tales.Travel suggestions more personalized?</h3>
            <p>
              You can personalize your experience in two ways:
            </p>
            <ol>
              <li><strong>Take Our Travel Quiz</strong>: Complete our travel quiz, and we'll make recommendations based on your unique travel style and preferences.</li>
              <li><strong>Specify Your Preferences</strong>: Be specific when you ask travel-related questions. For example, if you prefer boutique hotels, gourmet restaurants, or outdoor adventures, include those details in your queries.</li>
            </ol>
            
            <h3>### Does Tales.Travel store my personal information?</h3>
            <p>
              We store your personal preferences from the travel quiz, along with information you provide during signup and on your profile page. We handle payment information securely and do not store your credit card details on our servers. Our payment processing partners are PCI-compliant to ensure your data is protected.
            </p>
            
            <h3>### How is Tales.Travel different from other travel planning tools?</h3>
            <p>
              While other platforms may offer basic recommendations, Tales.Travel provides a comprehensive, personalized experience enriched with detailed content, stunning imagery, and extensive data integrations. With us, you don't just read about destinations—you immerse yourself in them. Our advanced AI technology and partnerships with reliable data sources create a powerful and customized travel planning experience.
            </p>
            
            <h3>### If I have an issue with a booking made through Tales.Travel, whom should I contact?</h3>
            <p>
              We're sorry for any inconvenience you're experiencing. You should have received a booking confirmation and contact information from the provider with whom you booked. Please reach out to them directly, as they are best equipped to assist you.
            </p>
            <p>
              If you're unable to resolve the issue or would like to provide feedback on your booking experience, please contact us at <a href="mailto:support@tales.travel">support@tales.travel</a>, and we'll do our best to help.
            </p>
            
            <h3>### How do I provide feedback on Tales.Travel?</h3>
            <p>
              We value all feedback—positive or constructive. Please let us know your thoughts so we can continually improve our platform for you. You can reach us at <a href="mailto:support@tales.travel">support@tales.travel</a>.
            </p>
            
            <h3>### How can I become a content contributor for Tales.Travel?</h3>
            <p>
              We're always looking for passionate travelers to share their experiences. If you're interested in contributing, please contact us at <a href="mailto:support@tales.travel">support@tales.travel</a> for more information.
            </p>
            
            <h3>### How can I contact Tales.Travel about partnerships?</h3>
            <p>
              Thank you for your interest in partnering with us. Please email us at <a href="mailto:support@tales.travel">support@tales.travel</a>, and we'll get back to you shortly.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Help;