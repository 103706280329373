import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/404.css';
import FlappyBird404 from '../components/FlappyBird404';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleRestart = () => {
    navigate('/'); // Navigate back to the Landing Page
  };

  return (
    <div className="not-found-page">
      {/* Overlay Message */}
      <div className="not-found-message">
        <h1>404 - Page Not Found</h1>
        <p>Oops! You seem to have taken a wrong turn.</p>
        <p>Click or press space to play and find your way back!</p>
      </div>
      {/* Flappy Bird Game */}
      <FlappyBird404 />
      {/* Optional Restart Button */}
      { /* You can uncomment the below if you want a visible button */
      /*
      <button className="restart-button" onClick={handleRestart}>
        Go to Home
      </button>
      */
      }
    </div>
  );
};

export default NotFoundPage;
