import React from 'react';

const PasswordStrengthMeter = ({ password }) => {
  const getPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const strength = getPasswordStrength(password);
  const percentage = (strength / 4) * 100;

  const getStrengthText = (strength) => {
    switch (strength) {
      case 0: return 'Weak';
      case 1: return 'Fair';
      case 2: return 'Good';
      case 3: return 'Strong';
      case 4: return 'Very Strong';
      default: return '';
    }
  };

  const getStrengthColor = (percentage) => {
    if (percentage <= 25) return '#e57373';
    if (percentage <= 50) return '#ffb74d';
    if (percentage <= 75) return '#aed581';
    return '#81c784';
  };

  return (
    <div className="signin-password-strength-meter">
      <div className="signin-strength-bar">
        <div
          className="signin-strength-bar-fill"
          style={{
            width: `${percentage}%`,
            backgroundColor: getStrengthColor(percentage)
          }}
        ></div>
      </div>
      <div className="signin-strength-text">
        {getStrengthText(strength)}
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
