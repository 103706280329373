import React, { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { trackEvent } from '../analytics';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        try {
          const result = await signInAnonymously(auth);
          console.log('Signed in anonymously:', result.user.uid);
          trackEvent({
            action: 'sign_in',
            category: 'Authentication',
            label: 'Anonymous Sign-In',
            value: 1,
          });
        } catch (error) {
          console.error('Error signing in anonymously:', error);
          trackEvent({
            action: 'sign_in_error',
            category: 'Authentication',
            label: 'Anonymous Sign-In Error',
            value: 1,
          });
        }
      } else {
        trackEvent({
          action: 'sign_in',
          category: 'Authentication',
          label: 'User Sign-In',
          value: 1,
        });
      }
      setCurrentUser(auth.currentUser);
      setLoading(false); // Update loading state
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
